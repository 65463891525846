/* Root Variables */
:root {
  --primary-color: #971131;
  --primary-light: #a82235;
  --primary-dark: #5a1018;
  --white-color: #f8f7f7;
  --black-color: #212121;
  --navy-color: #04101A;
  --grey-color: #D9D9D9;
  --pink-color: #C62E51;
  --dark-grey: #5b5959;
  --hover-grey: #5a5657;
  --nav-bg: #464344;
  --card-bg: #1a1a1e;
  --overlay-bg: rgba(0, 0, 0, 0.95);
  --success-color: #4caf50;
  --border-radius: 8px;
  --box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  --transition-speed: 0.3s;
  --secondary-color: #DE8DCB;
  --accent-color: #FF934F;
  --background-color: #1a1a1e;
  --text-color: #f8f7f7;
  --border-color: #333;
  --hover-color: rgba(151, 17, 49, 0.8);
  --shadow-color: rgba(0, 0, 0, 0.3);
  --modal-overlay: rgba(0, 0, 0, 0.85);
  --button-hover-transform: translateY(-3px) scale(1.03);
  --button-active-transform: translateY(1px) scale(0.98);
}

/* Base Styles */
html, body {
  margin: 0;
  padding: 0;
  font-family: 'Helvetica', sans-serif;
  height: 100%;
  background: var(--navy-color);
  color: var(--white-color);
}

/* Layout */
.gallery-container {
  display: flex;
  min-height: 100vh;
  background-color: var(--navy-color);
}

.sidebar {
  width: 15%;
  background-color: var(--black-color);
  color: var(--white-color);
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  box-shadow: var(--box-shadow);
}

.tags-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.tag-button {
  padding: 8px 12px;
  border: none;
  border-radius: 500px;
  background-color: var(--nav-bg);
  color: var(--white-color);
  cursor: pointer;
  transition: all var(--transition-speed) ease;
}

.tag-button.active {
  background-color: var(--primary-color);
  transform: scale(1.05);
}

.tag-button:hover {
  background-color: var(--pink-color);
  color: #fff;
  transform: scale(1.05);
}

.nav-item:hover {
  background-color: var(--hover-grey);
  color: var(--white-color);
}

/* Gallery Content */
.gallery-content {
  flex-grow: 1;
  padding: 20px;
  color: var(--text-color);
  max-width: 1200px;
  margin: 0 auto;
  background-color: var(--background-color);
}

.gallery-title {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  color: var(--text-color);
  text-align: center;
  border-bottom: 2px solid var(--primary-color);
  padding-bottom: 10px;
}

/* Controls */
.gallery-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  flex-wrap: wrap;
  gap: 1rem;
}

.control-group {
  display: flex;
  gap: 0.5rem;
}

.control-button, .sort-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background-color: #222226;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: all 0.2s ease;
  color: var(--text-color);
}

.control-button:hover, .sort-button:hover {
  background-color: var(--hover-color);
}

.control-button.active, .sort-button.active {
  background-color: var(--primary-color);
  color: white;
}

.button-icon {
  display: flex;
  align-items: center;
}

.sort-controls {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.sort-label {
  font-size: 0.9rem;
  color: var(--text-color);
}

/* Year Groups */
.year-group {
  margin-bottom: 2rem;
}

.year-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 1.5rem 0 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: var(--text-color);
  transition: color 0.3s ease;
  user-select: none;
  position: relative;
  padding-left: 0.5rem;
}

.year-title::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 4px;
  height: 70%;
  background-color: var(--primary-color);
  border-radius: 2px;
  transition: height 0.3s ease, background-color 0.3s ease;
}

.year-title:hover {
  color: var(--primary-color);
}

.year-title:hover::before {
  height: 90%;
  background-color: var(--accent-color);
}

.year-toggle {
  transition: transform 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
  display: inline-block;
  margin-left: 0.5rem;
}

.year-title.collapsed .year-toggle {
  transform: rotate(-90deg);
}

/* Gallery Grid */
.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 0.75rem;
  padding: 1rem 0;
}

.gallery-item {
  height: fit-content;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px var(--shadow-color);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  background-color: #222226;
  opacity: 1;
}

.gallery-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 12px var(--shadow-color);
}

.gallery-item.dimmed {
  opacity: 0.6;
}

.gallery-item.pending-border {
  border: 2px solid var(--accent-color);
}

/* Add dimming overlay for pending images */
.gallery-item.pending-border .thumbnail-container::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  pointer-events: none;
}

.thumbnail-container {
  position: relative;
  cursor: pointer;
  aspect-ratio: 1 / 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0a0a0e;
  overflow: hidden;
}

.gallery-thumbnail {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: transform 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
}

.thumbnail-container:hover .gallery-thumbnail {
  transform: scale(1.08);
}

/* Remove redundant image-overlay */
.image-overlay {
  display: none;
}

/* Fix position of GIF indicator to bottom left */
.gif-indicator {
  position: absolute;
  background-color: var(--accent-color);
  color: #000;
  padding: 2px 6px;
  font-size: 0.7rem;
  font-weight: bold;
  border-radius: 4px;
  z-index: 5;
  bottom: 8px;
  left: 8px;
}

.view-count {
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 0.25rem 0.5rem;
  border-bottom-left-radius: 8px;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  z-index: 6;
}

.view-icon {
  color: var(--white-color);
}

.view-number {
  color: var(--white-color);
  font-size: 0.8rem;
  font-weight: normal;
}

/* Modal */
.gallery-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--modal-overlay);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 20px;
  overflow-y: auto;
}

.gallery-modal-content {
  background-color: #222226;
  border-radius: 8px;
  width: 90%;
  max-width: 1200px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
  padding: 2rem;
  color: var(--text-color);
}

.modal-close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: rgba(0, 0, 0, 0.3);
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: var(--text-color);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  transition: background-color 0.2s ease;
}

.modal-close-button:hover {
  background-color: var(--primary-color);
}

.modal-nav-container {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  display: flex;
  gap: 1rem;
  z-index: 1001;
  transform: none;
}

/* Add placeholder styling */
.modal-nav-placeholder {
  width: 100px;
  height: 40px;
}

.modal-nav-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.25rem;
  color: white;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  transition: background-color 0.2s ease, transform 0.2s ease;
  box-shadow: 0 4px 8px var(--shadow-color);
  min-width: 120px;
  justify-content: center;
  background-color: var(--primary-color);
}

.modal-nav-button:hover {
  transform: translateY(-2px);
  background-color: var(--primary-light);
}

.modal-nav-button:active {
  transform: translateY(1px) scale(0.98);
  box-shadow: 0 2px 4px var(--shadow-color);
}

.nav-button-text {
  font-weight: 500;
}

.nav-button-icon {
  font-size: 1.2rem;
}

.prev-button .nav-button-icon {
  margin-right: 5px;
}

.next-button .nav-button-icon {
  margin-left: 5px;
}

.modal-top {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.modal-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 60vh;
  overflow: hidden;
  border-radius: 8px;
  background-color: #0a0a0e;
}

.gallery-modal-image {
  max-width: 100%;
  max-height: 60vh;
  object-fit: contain;
}

.modal-metadata {
  padding: 1rem 0;
}

.modal-metadata h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: var(--text-color);
}

.modal-stats {
  display: flex;
  gap: 2rem;
  margin-bottom: 1.5rem;
  flex-wrap: wrap;
  background-color: #1a1a1e;
  padding: 1rem;
  border-radius: 8px;
}

.stat-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--text-color);
}

.stat-value {
  font-size: 0.9rem;
}

.modal-description {
  margin-bottom: 1.5rem;
  line-height: 1.6;
  color: var(--text-color);
  max-height: 200px;
  overflow-y: auto;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  scrollbar-width: thin;
  scrollbar-color: var(--primary-color) #333;
}

.modal-description::-webkit-scrollbar {
  width: 8px;
}

.modal-description::-webkit-scrollbar-track {
  background: #333;
  border-radius: 8px;
}

.modal-description::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
  border-radius: 8px;
}

.tags-section {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
}

.modal-tag {
  padding: 0.25rem 0.75rem;
  background-color: #333;
  border-radius: 50px;
  font-size: 0.8rem;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.modal-tag:hover, .modal-tag.active {
  background-color: var(--primary-color);
  color: white;
}

.modal-actions {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  margin-top: 1.5rem;
}

.action-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  padding: 0.85rem 1.5rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  transition: all 0.2s ease;
  color: var(--text-color);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  min-width: 160px;
}

.action-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.3);
}

.action-button:active {
  transform: translateY(1px);
}

.download-button {
  background-color: var(--primary-color);
}

.download-button:hover {
  background-color: var(--primary-light);
}

.open-tab-button,
.copy-button {
  background-color: #2a2a2e;
  color: white;
}

.open-tab-button:hover,
.copy-button:hover {
  background-color: #444448;
}

.copy-button.copied {
  background-color: var(--success-color);
  color: white;
}

.copy-button.copied::before {
  display: none;
}

.no-results {
  text-align: center;
  padding: 3rem;
  color: var(--text-color);
  background-color: #222226;
  border-radius: 8px;
}

/* Animation for SVG icons */
.sort-icon,
.expand-icon,
.button-icon svg {
  transition: transform 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
  display: inline-block;
  transform-origin: center;
}

.sort-icon.active,
.expand-icon.active,
.active .button-icon svg {
  transform: rotate(180deg);
}

/* Responsive adjustments */
@media (min-width: 768px) {
  .modal-top {
    flex-direction: row;
  }
  
  .modal-image-container {
    flex: 1;
  }
  
  .modal-metadata {
    flex: 1;
    padding: 0 0 0 2rem;
  }
}

@media (max-width: 767px) {
  .gallery-controls {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .modal-nav-container {
    bottom: 1rem;
    right: 1rem;
  }
  
  .modal-nav-button {
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
  }
  
  .gallery-modal-content {
    padding: 1rem;
    width: 95%;
  }
}

/* Add button press animation */
@keyframes button-press {
  0% { transform: translateY(-2px); }
  50% { transform: translateY(1px) scale(0.98); }
  100% { transform: translateY(-2px); }
}

.button-press {
  animation: button-press 0.3s ease;
}

/* Rename class for consistency */
.button-blink {
  animation: button-press 0.3s ease;
}
