html, body {
  margin: 0;
  padding: 0;
  font-family: 'Helvetica', sans-serif;
  height: 100%;
  background: #000;
  color: var(--white-color);
}

/* Overall Layout */
.layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}

/* Header (Mobile Only) */
.header {
  background: var(--black-color);
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hamburger-button,
.mobile-close-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  color: var(--primary-color);
  cursor: pointer;
  margin: 0 10px 0 0;
  padding: 5px;
  z-index: 1100;
}

.hamburger-button:hover,
.mobile-close-button:hover {
  color: var(--grey-color);
}

.site-title {
  margin: 0;
  font-size: 1.5rem;
  color: var(--white-color);
  text-decoration: none;
}

/* Main Content Area */
.content {
  flex: 1;
  overflow-y: auto;
  background-color: var(--navy-color);
  padding: 20px;
  margin: 0;
}

/* Sidebar */
.sidebar {
  background-color: var(--black-color);
  width: 200px;
  padding: 10px 20px;
  overflow-y: auto;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  transform: translateX(-100%);
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  z-index: 400;
}

.sidebar-open {
  transform: translateX(0);
}

.sidebar .nav-item-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0 0 10px;
  color: var(--white-color);
  text-decoration: none;
}

.nav-section {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 0;
  padding: 0;
}

.nav-item {
  text-decoration: none;
  text-align: center;
  background-color: var(--nav-bg);
  color: var(--white-color);
  font-size: 1.5rem;
  padding: 10px;
  border-radius: 60px;
  transition: background-color 0.3s ease;
}

.nav-item:not(.active):hover {
  background-color: var(--hover-grey);
}

.nav-item.active {
  background-color: var(--primary-color);
}

/* Tags Section */
.tags-container {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  margin-top: 10px;
  border-top: 2px solid var(--dark-grey);
}

.tags-container h3 {
  width: 100%;
  text-align: center;
  color: var(--white-color);
  margin: 0 0 10px;
  font-size: 1rem;
}

/* Responsive Adjustments */
@media (min-width: 768px) {
  .header {
    display: none;
  }

  .sidebar {
    transform: none;
    position: static;
    flex-shrink: 0;
  }

  .layout {
    flex-direction: row;
  }
}

/* For the Tag Settings panel */
.tag-settings {
  width: 100%;
  margin-bottom: 1rem;
  background-color: #333;
  border: 1px solid #666;
  border-radius: 4px;
  padding: 0.5rem 1rem;
}

.tag-settings-header {
  cursor: pointer;
  font-size: 1.1rem;
  font-weight: bold;
  margin: 0;
  padding: 0.5rem 0;
  color: #f8f7f7;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tag-settings-controls {
  margin-top: 0.5rem;
  border-top: 1px solid #666;
  padding-top: 0.5rem;
}

.tag-matching-logic,
.hide-tags-section {
  margin-bottom: 1rem;
}

.logic-button {
  margin-right: 0.5rem;
  padding: 0.3rem 0.6rem;
  border-radius: 4px;
  border: none;
  background-color: #444;
  color: white;
  cursor: pointer;
  transition: background-color 0.2s;
}

.logic-button.active {
  background-color: #791626; /* var(--primary-color) */
}

.logic-button:hover {
  background-color: #5a5657; /* var(--hover-grey) */
}

.hide-checkbox {
  display: block;
  margin-bottom: 0.25rem;
  color: #f8f7f7;
}

/* For the Copy Link button in the modal */
.gallery-modal-copy-link {
  padding: 10px 20px;
  border-radius: 20px;
  background-color: #444;
  color: #f8f7f7;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.gallery-modal-copy-link:hover {
  background-color: #791626;
  color: #fff;
}

/* Tag Settings container */
.tag-settings {
  width: 100%;
  margin-bottom: 1rem;
  background-color: #333;
  border: 1px solid #666;
  border-radius: 4px;
  padding: 0.5rem 1rem;
}

.tag-settings-header {
  cursor: pointer;
  font-size: 1.1rem;
  font-weight: bold;
  margin: 0;
  padding: 0.5rem 0;
  color: #f8f7f7;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tag-settings-controls {
  margin-top: 0.5rem;
  border-top: 1px solid #666;
  padding-top: 0.5rem;
}

.tag-matching-logic,
.hide-tags-section {
  margin-bottom: 1rem;
}

.logic-button {
  margin-right: 0.5rem;
  padding: 0.3rem 0.6rem;
  border-radius: 4px;
  border: none;
  background-color: #444;
  color: white;
  cursor: pointer;
  transition: background-color 0.2s;
}

.logic-button.active {
  background-color: #791626;
}

.logic-button:hover {
  background-color: #5a5657;
}

.hide-checkbox {
  display: block;
  margin-bottom: 0.25rem;
  color: #f8f7f7;
}

/* "Copy Link" button in the modal */
.gallery-modal-copy-link {
  padding: 10px 20px;
  border-radius: 20px;
  background-color: #444;
  color: #f8f7f7;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.gallery-modal-copy-link:hover {
  background-color: #791626;
  color: #fff;
}