/* Container & Header */
.links-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: white;
  padding: 20px;
  background-color: #1E1E1E; 
  position: relative;
  overflow: hidden;
}

.header .subtitle {
  font-family: 'Arial', sans-serif;
  font-size: 18px;
  color: #CCCCCC;
}

/* Divider */
.divider {
  width: 80%;
  max-width: 800px;
  border: none;
  height: 2px;
  margin: 50px 0 auto;
  background: linear-gradient(to right, #999, #444, #999);
  opacity: 0.6;
}

/* Links Grid */
.links-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  width: 100%;
  max-width: 1200px;
}

/* Buttons: Link & Support */
.link-button, .support-button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  padding: 15px 30px;
  border-radius: 50px;
  font-size: 18px;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* Striped, repeating gradient in the pseudo-element */
.link-button::after,
.support-button::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 200%;
  height: 100%;
  background: repeating-linear-gradient(
    45deg,
    rgba(255, 255, 255, .2),
    rgba(255, 255, 255, .2) 50px,
    rgba(0, 0, 0, .1) 50px,
    rgba(0, 0, 0, .1) 100px
  );
  opacity: 0.4;
  z-index: 0;
  animation: stripesMotion 15s linear infinite;
}

/* Speed up stripes on hover */
.link-button:hover::after,
.support-button:hover::after {
  animation-duration: 4s;
}

/* Elevate on hover */
.link-button:hover,
.support-button:hover {
  transform: scale(1.02);
  box-shadow: 8px 12px rgba(0, 0, 0, 1);
}

/* Keyframes for gentle, indefinite stripes movement */
@keyframes stripesMotion {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 400px 0;
  }
}

/* Keep the text above the stripes */
.link-button .button-text,
.support-button .button-text {
  position: relative;
  z-index: 1;
}

/* Link Button text color defaults to black,
   but can be overridden by inline style. */
.link-button {
  color: black;
}

/* SubscribeStar Button:
   - Use #FF4081 background
   - Pinkish glow on hover 
*/
.support-button {
  font-size: 20px;
  padding: 15px 40px;
  border: 2px solid #FF4081; 
  color: white; /* Already overridden inline, just in case */
  background: #FF4081; 
}

/* Support Heading: gold color, alternate glow to white */
.support-heading {
  font-family: 'Helvetica', sans-serif;
  font-size: 32px;
  margin-bottom: 20px;
  color: #FF4081; /* gold */
  letter-spacing: 1px;
}

@keyframes gold-white-glow {
  0% {
    color: #FFD700;
    text-shadow: 0 0 3px #FFD700, 0 0 3px #FFD700;
  }
  100% {
    color: #FFFFFF;
    text-shadow: 0 0 3px #FFFFFF, 0 0 5px #FFFFFF;
  }
}

/* Responsive */
.links-grid .link-button,
.links-grid .support-button {
  flex: 1 1 calc(25% - 20px);
  max-width: 220px;
}

@media (max-width: 1024px) {
  .links-grid .link-button,
  .links-grid .support-button {
    flex: 1 1 calc(33.33% - 20px);
    max-width: 180px;
  }
}

@media (max-width: 768px) {
  .links-grid .link-button,
  .links-grid .support-button {
    flex: 1 1 calc(50% - 20px);
    max-width: 160px;
  }
}

@media (max-width: 480px) {
  .links-grid .link-button,
  .links-grid .support-button {
    flex: 1 1 100%;
    max-width: 100%;
  }
}

/* Focus States for Accessibility */
.link-button:focus,
.support-button:focus {
  outline: 2px solid #FF4081;
  outline-offset: 4px;
}
